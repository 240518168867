import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Layout1 from '@/layouts/Layout1'
import ModulePage from '@/pages/ModulePage'
import PlaygroundPage from '@/pages/PlaygroundPage'
import { fetchLaunch } from '@/store/launch/actions'

const LaunchContentPage = () => {
  const dispatch = useDispatch()
  const { launchId } = useParams()

  const { launchData } = useSelector((state) => state.launch)

  const handleLaunchLoad = async () => {
    await dispatch(
      fetchLaunch(
        launchId,
        null, // (data) => handleModuleLoad(data?.launch_data?.content_id),
        () => window.location.replace('/session-expired'),
      ),
    )
  }

  useEffect(() => {
    if (!launchId) return

    handleLaunchLoad()
  }, [launchId])

  if (launchData?.launch_data) {
    const contentType = launchData?.launch_data?.content_type

    if (contentType === 'module') {
      return <ModulePage launchModuleId={launchData?.launch_data?.content_id} />
    }

    if (contentType === 'playground') {
      return <PlaygroundPage launchPlaygroundId={launchData?.launch_data?.student_copy_id} />
    }
  }

  return <Layout1 isLoading={true} />
}

export default LaunchContentPage
