import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Spin } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import ProTooltip from '@/components/ProTooltip'
import WizardSteps from '../WizardSteps'
import DataSourceSelection from '../DataSourceSelection'
import DataSourceDetail from '../DataSourceDetail'
import DataSourceSettings from '../DataSourceSettings'
import JumpboxSelection from '../JumpboxSelection'
import { toggleEmailVerificationModal } from '@/store/users/actions'
import {
  updateWizard,
  toggleWizardModal,
  resetWizard,
  getPlayground,
  createPlayground,
  updatePlayground,
} from '@/store/playgrounds/actions'
import { resetCurrentDataSource } from '@/store/dataSources/actions'
import { getJumpboxes } from '@/store/jumpboxes/actions'
import { Container } from './styles'

const WizardContent = ({ isCustomProject }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { accountId, projectId } = useParams()

  const [isLoading, setIsLoading] = useState(true)

  const { userProfile, emailValidationCodeIsValidated } = useSelector((state) => state.users)
  const { items: jumpboxes } = useSelector((state) => state.jumpboxes)
  const {
    currentPlayground,
    wizard,
    isLoading: isPlaygroundsLoading,
    error,
  } = useSelector((state) => state.playgrounds)

  const requireEmailValidated = userProfile?.organization?.config?.launch_labs_requires_email_validated
  const canRunProDataSource = userProfile?.permissions?.includes('playgrounds.run_pro_data_source')

  let continueButtonText = ''
  let continueButtonOnClick = () => {}
  let backButtonText = 'Back'
  let isDataSourcePro = false
  let allowBack = wizard?.jumpbox || wizard?.preDataSource || wizard?.dataSource

  let handleBackButtonClick = () => {
    if (wizard?.jumpbox && !wizard?.preDataSource && !wizard?.dataSource) {
      dispatch(toggleWizardModal(false))
      return dispatch(updateWizard({ jumpbox: null }))
    }

    if (wizard?.preDataSource && !wizard?.dataSource) {
      if (wizard?.type === '2') {
        dispatch(toggleWizardModal(false))
      }

      dispatch(resetCurrentDataSource())
      return dispatch(updateWizard({ preDataSource: null }))
    }

    if (!wizard?.jumpbox && wizard?.preDataSource && wizard?.dataSource) {
      if (wizard?.type === '1') {
        dispatch(toggleWizardModal(false))
      }
      return dispatch(updateWizard({ dataSource: null }))
    }

    if (wizard?.jumpbox && wizard?.preDataSource && wizard?.dataSource) {
      return dispatch(updateWizard(wizard?.type === '2' ? { jumpbox: null } : { dataSource: null }))
    }
  }

  const handleUpdatePlayground = () => {
    const playground = {
      name: wizard?.name || `${wizard?.dataSource?.name || wizard?.jumpbox?.name} playground`,
      description_md: wizard?.description,
      image_url: wizard?.imageUrl,
      boost: wizard?.boost,
      internet_on: wizard?.internetOn,
    }

    if (!playground.description_md) delete playground.description_md

    dispatch(
      updatePlayground(wizard?.playgroundId, playground, () => {
        showToast(`${isCustomProject ? 'Project' : 'Playground'} updated successfully`, 'success')
        dispatch(toggleWizardModal(false))
      }),
    )
  }

  const renderStep = () => {
    if (!wizard?.jumpbox) {
      continueButtonText = 'Continue'
      continueButtonOnClick = () => {
        if (!wizard?.preJumpbox) {
          showToast('Please select an environment to your project', 'error')
          return
        }

        dispatch(updateWizard({ jumpbox: wizard?.preJumpbox }))
      }

      return <JumpboxSelection isCustomProject={isCustomProject} />
    }

    if (!wizard?.preDataSource && !wizard?.dataSource) {
      continueButtonText = 'Continue without data'
      continueButtonOnClick = () => dispatch(updateWizard({ preDataSource: {} }))

      return <DataSourceSelection />
    }

    if (wizard?.preDataSource && !wizard?.dataSource) {
      continueButtonText = wizard?.preDataSource?.id ? 'Use dataset' : 'Continue'
      continueButtonOnClick = () => dispatch(updateWizard({ dataSource: wizard?.preDataSource }))
      isDataSourcePro = wizard?.preDataSource?.is_pro

      return <DataSourceDetail isCustomProject={isCustomProject} />
    }

    if (wizard?.jumpbox && wizard?.preDataSource && wizard?.dataSource) {
      continueButtonText = `Create ${isCustomProject ? 'Project' : 'Playground'}`
      continueButtonOnClick = () => {
        if (!userProfile?.email_validated_at && requireEmailValidated && !emailValidationCodeIsValidated) {
          dispatch(toggleEmailVerificationModal(true))
          return
        }

        if (isCustomProject && !wizard.name) {
          showToast('Please add a name to your project', 'error')
          return
        }

        let playground = {
          name: wizard?.name || `${wizard?.dataSource?.name || wizard?.jumpbox?.name} playground`,
          description_md: wizard?.description,
          image_url: wizard?.imageUrl,
          jumpbox: wizard?.jumpbox?.id,
          data_sources: wizard?.dataSource?.id ? [wizard?.dataSource?.id] : [],
          boost: wizard?.boost,
          internet_on: wizard?.internetOn,
          visibility: isCustomProject ? 'unlisted' : 'public',
        }

        if (!playground.description_md) delete playground.description_md

        if (isCustomProject) {
          playground.metadata = {
            account_id: accountId,
            is_student_copy: false,
          }
        }

        dispatch(
          createPlayground(playground, (p) => {
            if (!isCustomProject) {
              dispatch(toggleWizardModal(false))
            }

            if (isCustomProject) {
              navigate(`/custom-projects/${accountId}/${p?.id}?mode=edit`)
            } else {
              navigate(`/playgrounds/${p?.id}?mode=edit`)
            }
          }),
        )
      }

      return <DataSourceSettings isCustomProject={isCustomProject} />
    }
  }

  useEffect(() => {
    if (jumpboxes) return

    if (jumpboxes === null) {
      dispatch(getJumpboxes())
    }
  }, [jumpboxes])

  useEffect(() => {
    if (wizard?.jumpbox && !wizard?.preDataSource) {
      // start wizard from data source selection
      dispatch(updateWizard({ type: '1' }))
      return
    }

    if (!wizard?.jumpbox && wizard?.preDataSource) {
      // start wizard with jumpbox selection
      dispatch(updateWizard({ type: '2' }))
      return
    }

    // start without any selection
    dispatch(updateWizard({ type: '0' }))

    return () => {
      if (wizard?.isWizardModalOpen) {
        dispatch(resetCurrentDataSource())

        setTimeout(() => dispatch(resetWizard()), 400)
      }
    }
  }, [wizard?.isWizardModalOpen])

  useEffect(() => {
    if (!projectId) {
      setIsLoading(false)
      return
    }

    if (!currentPlayground) {
      dispatch(getPlayground(projectId))
      return
    }

    const isAccountOwner =
      userProfile?.accounts?.find((a) => a.id === currentPlayground?.metadata?.account_id)?.role === 'owner'
    const isSuperAccountOwner = userProfile?.permissions?.includes('teams.super_account_owner')

    if (isCustomProject && !(isAccountOwner || isSuperAccountOwner)) {
      navigate('/', { replace: true })
    }

    dispatch(
      updateWizard({
        isEdit: true,
        playgroundId: currentPlayground?.id,
        name: currentPlayground?.name,
        description: currentPlayground?.description_md,
        imageUrl: currentPlayground?.image_url,
        jumpbox: currentPlayground?.jumpbox,
        preDataSource: currentPlayground?.data_sources?.[0] || {},
        dataSource: currentPlayground?.data_sources?.[0] || {},
        boost: currentPlayground?.config?.boost || false,
        internetOn: currentPlayground?.config?.internet_on || false,
      }),
    )
    setIsLoading(false)
  }, [projectId, currentPlayground])

  useEffect(() => {
    const isAccountOwner = accountId && userProfile?.accounts?.find((a) => a.id === accountId)?.role === 'owner'
    const isSuperAccountOwner = userProfile?.permissions?.includes('teams.super_account_owner')

    if (isCustomProject && !(isAccountOwner || isSuperAccountOwner)) {
      navigate('/', { replace: true })
    }
  }, [])

  if (error) {
    return (
      <Container className="wizard-content-container">
        <div className="error-container">
          <p>{error || 'Something went wrong'}</p>
        </div>
      </Container>
    )
  }

  if (isLoading) {
    return (
      <Container className="wizard-content-container">
        <div className="loading-container">
          <Spin size="large" />
        </div>
      </Container>
    )
  }

  return (
    <Container className="wizard-content-container">
      <div className="wizard-content">
        {!wizard?.isEdit && <WizardSteps isCustomProject={isCustomProject} />}

        <div className={wizard?.isEdit ? 'step-content center' : 'step-content'}>{renderStep()}</div>
      </div>

      <div className="actions">
        {!wizard?.isEdit ? (
          <>
            {allowBack ? (
              <Button type="text" onClick={handleBackButtonClick} loading={isPlaygroundsLoading}>
                <ArrowLeftOutlined className="back-icon" />
                {backButtonText}
              </Button>
            ) : (
              <span />
            )}

            {isDataSourcePro && !canRunProDataSource ? (
              <ProTooltip
                title="Exclusive dataset"
                content={
                  <p className="text">
                    <b>Upgrade to PRO</b> to use this and many other exclusive datasets.
                  </p>
                }
                showUpgradeButton
              >
                <Button type="primary">{continueButtonText}</Button>
              </ProTooltip>
            ) : (
              <Button type="primary" onClick={continueButtonOnClick} loading={isPlaygroundsLoading}>
                {continueButtonText}
              </Button>
            )}
          </>
        ) : (
          <>
            <span />
            <Button type="primary" onClick={handleUpdatePlayground} loading={isPlaygroundsLoading}>
              Update {isCustomProject ? 'project' : 'playground'}
            </Button>
          </>
        )}
      </div>
    </Container>
  )
}

export default WizardContent
