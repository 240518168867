import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { isDataWarsHostName } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import { isDown } from '@/themes/breakpoints'
import Layout1 from '@/layouts/Layout1'
import ShareModal from '@/components/ShareModal'
import InactivityModal from '@/pages/ModulePage/components/InactivityModal'
import SiderMenu from './components/SiderMenu'
import InfoSideDrawer from './components/InfoSideDrawer'
import PlaygroundHeader from './components/PlaygroundHeader'
import PlaygroundContent from './components/PlaygroundContent'
import MultiplePlaygroundsModal from './components/MultiplePlaygroundsModal'
import ResetPlaygroundModal from './components/ResetPlaygroundModal'
import PublishModal from './components/PublishModal'
import PlaygroundsFeedbackModal from '@/pages/PlaygroundsPage/components/PlaygroundsFeedbackModal'
import PlaygroundsFeedbackTag from '@/pages/PlaygroundsPage/components/PlaygroundsFeedbackTag'
import { toggleEmailVerificationModal } from '@/store/users/actions'
import {
  getPlayground,
  updatePlaygroundMode,
  getAllocatedPlaygrounds,
  toggleMultiplePlaygroundsModal,
  resetPlaygroundsState,
} from '@/store/playgrounds/actions'
import { getLab, getSession, allocateLab, setInactivityModalOpen, resetLabsState } from '@/store/labs/actions'
import { Container } from './styles'

const PlaygroundPage = ({ launchPlaygroundId }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { playgroundId } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()

  const modeSearchParam = searchParams.get('mode')

  const { currentAccount } = useSelector((state) => state.accounts)
  const { isAuthenticated, userProfile, emailValidationCodeIsValidated } = useSelector((state) => state.users)
  const {
    currentPlayground: playground,
    playgroundMode,
    allocatedPlaygrounds,
    error: playgroundError,
  } = useSelector((state) => state.playgrounds)
  const { currentLab, labPingError } = useSelector((state) => state.labs)

  const labSession = currentLab?.allocated_session
  const isDeviceStopping = labSession?.devices?.some((d) => d.status === 'stopping')
  const isLabSessionAllocated = labSession?.status === 'allocated'

  const requireEmailValidated = userProfile?.organization?.config?.launch_labs_requires_email_validated
  const isEmailValidated = userProfile?.email_validated_at || emailValidationCodeIsValidated
  const canRunMultipleLabs = userProfile?.permissions?.includes('labs.run_multiple_labs')
  const canLaunchPlayground =
    canRunMultipleLabs ||
    (!canRunMultipleLabs &&
      allocatedPlaygrounds &&
      !allocatedPlaygrounds?.filter((p) => p?.playground_id !== playground?.id)?.length)

  const doAllocateLab = async () => {
    const powerOnAllDevices = true
    const config = {
      resume: true,
      ...(playground?.config?.boost ? { boost: true } : {}),
      ...(playground?.config?.internet_on ? { internet_on: true } : {}),
    }

    await dispatch(allocateLab(playground?.lab_id, 'playground', powerOnAllDevices, config))
  }

  const tryAllocateLab = () => {
    if (!userProfile?.email_validated_at && requireEmailValidated && !emailValidationCodeIsValidated) {
      dispatch(toggleEmailVerificationModal(true))
      return
    }

    if (!canLaunchPlayground) {
      if (playgroundMode === 'preview') {
        dispatch(toggleMultiplePlaygroundsModal(true))
      }
      return
    }

    dispatch(updatePlaygroundMode('edit'))

    if (!isLabSessionAllocated) {
      doAllocateLab()
    }
  }

  useEffect(() => {
    if (!modeSearchParam || ['edit', 'preview'].includes(modeSearchParam)) {
      const newPlaygroundMode = isEmailValidated ? modeSearchParam || 'preview' : 'preview'

      dispatch(updatePlaygroundMode(newPlaygroundMode))
      setSearchParams({ mode: newPlaygroundMode }, { replace: true })
    }
  }, [modeSearchParam]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!playgroundMode) return
    setSearchParams({ mode: playgroundMode }, { replace: true })
  }, [playgroundMode]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!playgroundId && !launchPlaygroundId) return

    dispatch(setInactivityModalOpen(false))
    dispatch(getPlayground(launchPlaygroundId || playgroundId))
    dispatch(getAllocatedPlaygrounds())
  }, [playgroundId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!playground) return
    dispatch(resetLabsState())

    const isAccountOwner =
      userProfile?.accounts?.find((account) => account.id === playground?.metadata?.account_id)?.role === 'owner'
    if (!isAccountOwner && !userProfile?.is_staff) {
      navigate('/', { replace: true })
    }

    const title = (isDataWarsHostName ? 'DataWars' : currentAccount?.name) || 'Practice projects'
    const fullTitle = `${title} - Become an expert Data Scientist`

    let playgroundDescription = `This playground uses ${playground?.jumpbox?.language?.value}`
    if (playground?.data_sources?.[0]) {
      playgroundDescription += ` to explore a dataset about ${playground?.data_sources?.[0]?.name}: ${playground?.data_sources?.[0]?.short_description_md}`
    }

    document.title = `${playground?.name} | ${title}`
    document.querySelector("meta[property='og:title'").setAttribute('content', `${playground?.name} | ${title}`)
    document.querySelector("meta[name='description'").setAttribute('content', playgroundDescription)
    document.querySelector("meta[property='og:description'").setAttribute('content', playgroundDescription)

    if (playground?.lab_id) {
      dispatch(getLab(playground.lab_id))
    }

    return () => {
      document.title = title
      document.querySelector("meta[property='og:title'").setAttribute('content', fullTitle)
      document
        .querySelector("meta[name='description'")
        .setAttribute('content', 'Project-based playground with +1000 ready-to-solve challenges.')
      document
        .querySelector("meta[property='og:description'")
        .setAttribute('content', 'Project-based playground with +1000 ready-to-solve challenges.')
    }
  }, [playground?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!currentLab) return

    if (!currentLab?.allocated_session) {
      if (playgroundMode === 'edit') {
        tryAllocateLab()
      }

      if (currentLab?.justStopped) {
        dispatch(getPlayground(playgroundId))
        dispatch(getAllocatedPlaygrounds())
      }
    }

    let refreshSessionInterval
    if (isAuthenticated && isDeviceStopping) {
      refreshSessionInterval = setInterval(() => {
        dispatch(getSession(currentLab?.allocated_session?.id))
      }, 1000)
    }

    return () => {
      if (!currentLab?.allocated_session) return

      clearInterval(refreshSessionInterval)
    }
  }, [currentLab?.allocated_session]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (canLaunchPlayground && playgroundMode === 'edit' && currentLab && !currentLab?.allocated_session) {
      tryAllocateLab()
    }
  }, [canLaunchPlayground])

  useEffect(() => {
    if (!labPingError) return

    dispatch(updatePlaygroundMode('preview'))
  }, [labPingError])

  useEffect(() => {
    if (playgroundError) {
      showToast('There was an error loading content', 'error')
    }
  }, [playgroundError])

  useEffect(() => {
    return () => {
      dispatch(resetPlaygroundsState())
      dispatch(resetLabsState())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1 navbar siderContent={!isDown('md') && !playgroundError && <SiderMenu />} disableSiderCollapse>
      {playground && <InfoSideDrawer />}

      <Container className="playground-page">
        {<PlaygroundHeader tryAllocateLab={tryAllocateLab} />}

        <PlaygroundContent canLaunchPlayground={canLaunchPlayground} tryAllocateLab={tryAllocateLab} />

        {localStorage.getItem('dw-debug') && (
          <div
            className="debug-box"
            style={{
              width: 200,
              background: 'rgba(127,127,127,0.3',
              position: 'absolute',
              right: 0,
              top: '30%',
              padding: 10,
            }}
          >
            <p>
              pg mode?{' '}
              <span
                style={{ background: playgroundMode === 'edit' ? '#00ffff' : '#00ff00', fontWeight: 600, padding: 2 }}
              >
                {playgroundMode || '-'}
              </span>
            </p>
            <p>pg? {playground ? '✅' : '❌'}</p>
            <p>lab? {currentLab ? '✅' : '❌'}</p>
            <p>
              l.session? {labSession ? '✅' : '❌'} ({labSession?.status})
            </p>
            <p>p.preview_html? {playground?.preview_html ? '✅' : '❌'}</p>
            <p>isDeviceStopping? {isDeviceStopping ? '✅' : '❌'}</p>
          </div>
        )}
      </Container>

      <PlaygroundsFeedbackTag />

      <InactivityModal callback={() => dispatch(updatePlaygroundMode('preview'))} />
      <MultiplePlaygroundsModal />
      <PublishModal />
      <ShareModal contentType="playground" />
      <ResetPlaygroundModal />
      <PlaygroundsFeedbackModal />
    </Layout1>
  )
}

export default PlaygroundPage
